import style from "./app.scss";
import { loadGmap3 } from "./scripts/loadGmap3";
import { focusFirstError } from "@atyoursite/npm-library";

//* Console log site name & pagename in colors
console.log(
    `%cHC Containers\n%cVerkoop & Verhuur van Containers`,
    "font-size:1.2rem;color:red",
    "font-size:1rem;color:#bbb"
);
/**/

//-- Imports
import {
    findBootstrap4Environment,
    closeCookie,
    scrollToTop,
    ieObjectFitImagesFix,
} from "@atyoursite/npm-library";

//-- Init
closeCookie("cookie-notice", 365, $("#cookie-notice"), $("#cn-accept-cookie"));
scrollToTop($("#backToTop"));
initObjScrollReveal();
initFancybox();
initBootstrapMultilevelNav();

//-- Functions
function initObjScrollReveal() {
    if ($(".reveal").length) {
        let objScrollReveal = ScrollReveal({ reset: true });
        objScrollReveal.reveal(".reveal", {
            duration: 1000,
            reset: false,
            delay: 500,
            distance: "0",
            viewFactor: 0.2,
        });
        objScrollReveal.reveal(".wysiwyg *", {
            duration: 1000,
            reset: false,
            delay: 500,
            distance: "0",
            viewFactor: 0.2,
        });
    }
}

function initFancybox() {
    $().fancybox({
        selector: '[data-fancybox="gallery"]',
        loop: true,
        buttons: ["close"],
    });
}

function initBootstrapMultilevelNav() {
    $(".dropdown-submenu > a").on("click", function (e) {
        var submenu = $(this);
        $(".dropdown-submenu .dropdown-menu").removeClass("show");
        submenu.next(".dropdown-menu").addClass("show");
        e.stopPropagation();
    });

    $(".dropdown").on("hidden.bs.dropdown", function () {
        // hide any open menus when parent closes
        $(".dropdown-menu.show").removeClass("show");
    });
}

// Contact
if ($("#contactWrapper").length) {
    loadGmap3($("#map"));
}

$("#frmContact").on("submit", function (e) {
    e.preventDefault();
    let myForm = this;
    let formData = new FormData(myForm);

    $(".error").removeClass("error");
    $("#frmContact").validate({
        errorPlacement: function (error, element) {},
        invalidHandler: function () {},
    });

    if ($("#frmContact").valid()) {
        // for (var pair of formData.entries()) {
        //     console.log(pair[0] + ", " + pair[1]);
        // }
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString(),
        })
        .then(() => {
            $("#msgResult").removeClass("d-none");
        })
        .catch((error) => {
            $("#msgError").removeClass("d-none");
        });
        $("#frmContact").addClass("d-none");
        $("#pnlResult").removeClass("d-none");
        $(window).scrollTop($("#contactWrapper").offset().top - 40);
    } else {
        focusFirstError($(".js-content").outerHeight());
    }
});
