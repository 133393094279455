export function loadScriptAsync(url) {
    return new Promise((resolve, reject) => {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.onload = function () {
            resolve("succes");
        };
        script.src = url;
        document.getElementsByTagName('body')[0].appendChild(script);
    });
}
