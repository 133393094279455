import { loadScriptAsync } from "./loadScriptAsync"; 

export function loadGmap3(mapSelector) {
    if (mapSelector.length) {
        let googleAPI = loadScriptAsync('//maps.googleapis.com/maps/api/js?v=3.53.13&key=AIzaSyDiSTG-ZME6sN11Zp91pTEnzbm8mZe64Fc');
        googleAPI.then((successMessage) => {
            let gmap = loadScriptAsync('//cdn.jsdelivr.net/gmap3/7.2.0/gmap3.min.js');
            gmap.then((successMessage) => {
                //Tester:--> let strAddress = 'Dorpstraat 12, 3500 hasselt';
                let strAddress = mapSelector.data("mapaddress");
                console.log(strAddress);
                mapSelector.gmap3({
                    //center: [50.9246523, 5.2434253],
                    address: strAddress,
                    zoom: 12,
                    mapTypeId: "shadeOfGrey" // to select it directly
                }).marker([{ address: strAddress }])
                    .styledmaptype(
                    "shadeOfGrey",
                    [
                        { "featureType": "all", "elementType": "geometry", "stylers": [{ "saturation": "-100" }, { "lightness": 0 }] },
                        { "featureType": "all", "elementType": "labels.text.fill", "stylers": [{ "saturation": "-100" }, { "lightness": 0 }] },
                        { "featureType": "all", "elementType": "labels.icon", "stylers": [{ "saturation": "-100" }, { "lightness": 0 }] }
                    ],
                    { name: "Shades of Grey" }
                    );
            });
        });
    }
}
